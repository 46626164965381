import React from 'react';
import {handleScrollToSection} from "../../utils";

const NavigationItem = ({name, target, link}) => {
    // Determine if the link is a mailto link
    const isMailto = link.startsWith('mailto:');

    // Determine if the link is an internal anchor
    const isAnchor = link.startsWith('#');

    const handleClick = (e) => {
        if (isAnchor) {
            e.preventDefault(); // Prevent default anchor behavior
            handleScrollToSection(target); // Execute smooth scrolling
        }
    };

    return (
        <a href={link} key={name} onClick={handleClick}
           target={!isAnchor && !isMailto ? '_blank' : undefined}
           rel={!isAnchor && !isMailto ? "noreferrer" : undefined}>
            {name}
        </a>
    );
};

export default NavigationItem;
