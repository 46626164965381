import {TwitterIcon} from "../../icons/socials/Twitter";
import {TelegramIcon} from "../../icons/socials/Telegram";

export const navigation = {
    solutions: [
        {name: 'How it works', href: '#solution', target: 'solution'},
        {name: 'Documentation', href: 'https://jigsaw.gitbook.io/jigsaw-protocol'},
    ],
    support: [
        {name: 'Contact', href: 'https://x.com/jigsawdefi'},
        {name: 'Terms', href: 'https://jigsaw.gitbook.io/jigsaw-protocol'},
        {name: 'Privacy', href: 'https://jigsaw.gitbook.io/jigsaw-protocol'},
    ],
    social: [
        {
            name: 'Twitter',
            href: 'https://x.com/jigsawdefi',
            icon: <TwitterIcon/>,
        },
        {
            name: 'Telegram',
            href: 'https://t.me/jigsawdefi',
            icon: <TelegramIcon/>
        },
    ],
}
