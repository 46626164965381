import {LogoLite} from "../../icons/base/LogoLite";
import {navigation} from "./mappings";
import NavigationItem from "../../base/NavigationItem";

export function Footer() {
    const currentYear = new Date().getFullYear() || 2024;

    return (
        <footer className="bg-gray-900" aria-labelledby="footer-heading">
            <div className="wrapper p-0 ">
                <div className='flex flex-col items-center pb-20 w-full flex-wrap max-sm:px-5'>
                    <div
                        className="flex flex-row max-sm:flex-col flex-wrap w-full px-6 justify-between items-center max-w-5xl max-sm:gap-16">
                        <div className="space-y-8">
                            <div className="max-sm:ml-10 ml-0">
                                <LogoLite/>
                            </div>
                            <div
                                className="flex flex-col gap-4 sm:gap-6 sm:flex-row items-center justify-center text-gray-500 hover:text-gray-400">
                                {navigation.social.map((item) => (
                                    <div className="flex gap-2 flex-wrap justify-center items-center">
                                        {item.icon ?? null}
                                        <NavigationItem key={item.name} name={item.name} link={item.href}/>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='flex flex-col justify-start items-start relative'>
                            <ul className="space-y-4 text-left max-sm:text-center text-sm leading-6 text-gray-300 hover:text-white">
                                {navigation.solutions.map((item) => (
                                    <li key={item.name}>
                                        <NavigationItem name={item.name} link={item.href} target={item.target ?? null}/>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div
                            className="flex flex-col justify-start items-start relative">
                            <ul className="space-y-4 text-left max-sm:text-center text-sm leading-6 text-gray-300 hover:text-white">
                                {navigation.support.map((item) => (
                                    <li key={item.name}>
                                        <NavigationItem name={item.name} link={item.href}/>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="flex mt-16">
                        <p className="text-xs leading-5 font-poppins font-normal text-lightGray">&copy; {currentYear} Jigsaw,
                            Inc.</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
