import React from 'react'
import {navigation} from "./mappings";
import NavigationItem from "../../base/NavigationItem";
import ConnectButton from "../../base/ConnectButton";

const DesktopMenu = () => {
    return (
        <div className="hidden items-center lg:flex lg:gap-x-12">
            {navigation.map(item => (
                <NavigationItem key={item.name} {...item} />
            ))}
            <ConnectButton/>
        </div>
    );
}
export default DesktopMenu
