import {useState} from 'react';
import {Bars3Icon} from '@heroicons/react/24/outline';
import {LogoLite} from "../../icons/base/LogoLite";
import DesktopMenu from "./DesktopMenu";
import MobileMenu from "./MobileMenu";


export default function Header() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    return (
        <header className="py-5 px-5 absolute w-full bg-transparent z-50">
            <nav className="mx-auto flex items-center justify-between" aria-label="Global">
                <a href="https://jigsaw.finance" aria-label='redirection to home page' className="-m-1.5 p-1.5">
                    <LogoLite/>
                </a>
                <div className="flex lg:hidden">
                    <button type="button"
                            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700 hover:bg-transparent"
                            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                        <span className="sr-only">Toggle mobile menu</span>
                        {!mobileMenuOpen && <Bars3Icon style={{color: 'white'}} className="h-6 w-6 hover:bg-transparent"
                                                       aria-hidden="true"/>}
                    </button>
                </div>
                <DesktopMenu/>
            </nav>
            <MobileMenu isOpen={mobileMenuOpen} closeMenu={() => setMobileMenuOpen(false)}/>
        </header>
    );
}
