import React from 'react'
import Button from "./Button";

const ConnectButton = () => {
    return (
        <a href='https://app.jigsaw.finance' target="_blank" rel="noreferrer">
            <Button label="Connect Wallet"/>
        </a>
    )
}
export default ConnectButton
