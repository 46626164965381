import React from 'react'
import {Dialog} from "@headlessui/react";
import {XMarkIcon} from "@heroicons/react/24/outline";
import {navigation} from "./mappings";
import NavigationItem from "../../base/NavigationItem";
import ConnectButton from "../../base/ConnectButton";

const MobileMenu = ({isOpen, closeMenu}) => {
    return (
        <Dialog as="div" className="lg:hidden" open={isOpen} onClose={closeMenu}>
            <div className="fixed inset-0 z-10"/>
            <Dialog.Panel
                className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-black px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                <div className="flex flex-row justify-between items-center pt-2">
                    <button type="button" className="-m-3 rounded-md p-2.5 text-gray-700 hover:bg-transparent"
                            onClick={closeMenu}>
                        <span className="sr-only">Close menu</span>
                        <XMarkIcon style={{color: 'white'}} className="h-6 w-6 hover:bg-transparent"
                                   aria-hidden="true"/>
                    </button>
                </div>
                <div className="mt-16 flow-root">
                    <div className="-my-6 divide-y divide-gray-500/10">
                        <div className="flex flex-col gap-6">
                            {navigation.map(item => (
                                <NavigationItem key={item.name} {...item} />
                            ))}
                        </div>
                        <div className="py-6">
                            <ConnectButton/>
                        </div>
                    </div>
                </div>
            </Dialog.Panel>
        </Dialog>
    );
}
export default MobileMenu
